import { Box, Button, Card, Modal, Select, Stack, Text, Title } from '@mantine/core';
import React, { useCallback, useEffect, useMemo } from 'react';

import { Eyebrow } from '@unserkunde/enscompare-components/src/components/components';

import ChecklistComponent from '@unserkunde/enscompare-components/src/components/feedback/Checklist';
import { useStyles as usePremiumserviceCardStyles } from '@unserkunde/enscompare-components/src/components/cards/PremiumServiceCard';

import { FiCheck } from 'react-icons/fi';
import { ActionHintLabel, TooltipLabel } from '../shared/HookedInputs';
import { useCustomProduct } from '@/reducer/customProducts';
import {
  CustomProductVariantSelection,
  CustomProductVariantState,
} from '@/reducer/customProducts/customProducts.types';
import { priceTypeToText, useProductPrice } from '@/reducer/customProducts/customProductPricing';

const selectionOptionsGeneralItems = [];

/**
 * @todo: Peise aus CustomProductsManager entnehmen
 */
const selectionOptions = {
  buy: {
    items: [
      ...selectionOptionsGeneralItems,
      {
        type: 'positive',
        children: 'GPS-Tracker zum Kauf',
      },
      {
        type: 'positive',
        children: 'Orte dein Bike per App',
      },
      {
        type: 'positive',
        children: 'Rückholservice',
      },
      {
        type: 'negative',
        children: (
          <>
            <TooltipLabel
              label={'GPS Abo inklusive'}
              helpText={
                <>
                  Sie können den GPS-Tracker über uns erwerben. Durch die Inbetriebnahme der Ortungssignale fallen
                  zusätzlich 30,-€ jährlich an, welche der Hersteller nach Aktivierung auf seiner Homepage einzieht.
                </>
              }
            />
          </>
        ),
      },
    ],
  },
  rent: {
    items: [
      ...selectionOptionsGeneralItems,
      {
        type: 'positive',
        children: 'GPS-Tracker als Leihgabe',
      },
      {
        type: 'positive',
        children: 'Orte dein Bike per App',
      },
    ],
  },
} as const;

export const GpsTrackerHeadpoints = selectionOptions.rent;

export const GpsSelectionCard = (props: {
  onChange?: (value: CustomProductVariantSelection<'gpsTracker'>) => void;
  onDisplayHeadpoints?: (value: any) => void;
  value?: CustomProductVariantState<'gpsTracker'>;
}) => {
  const { classes } = usePremiumserviceCardStyles();

  let { value, onChange } = props;
  value = value || 'unknown';

  const priceSelection = useProductPrice('gpsTracker', 'rented', 1);

  const priceText = `${priceSelection?.amount},-€ ${priceTypeToText(priceSelection?.priceType)}`;

  const [infoOpen, setInfoOpen] = React.useState(false);

  const GpsTrackerLabel = useMemo(
    () => (
      <ActionHintLabel
        td='underline'
        onClick={() => setInfoOpen(true)}
        style={{ cursor: 'pointer' }}>
        GPS-Tracker
      </ActionHintLabel>
    ),
    [setInfoOpen]
  );

  const [gpsProduct, _, setGpsProducsAmount] = useCustomProduct('gpsTracker');

  const headpointProps = selectionOptions.rent;

  useEffect(() => {
    if (!props.onDisplayHeadpoints) return;
    props.onDisplayHeadpoints(headpointProps);
  }, [headpointProps]);

  return (
    <>
      <Modal
        withinPortal={false}
        opened={infoOpen}
        onClose={() => setInfoOpen(false)}
        title='Informationen zum GPS Tracker'>
        <Text>
          Wir haben alle gängigen GPS-Tracker auf dem deutschen Markt getestet und uns für den GPS-Tracker von
          Bikefinder entschieden. Dieser kann ganz einfach im Lenker versteckt und befestigt werden. Die Akkulaufzeit
          ist mit bis zu 8 Wochen beachtlich und kann per USB-C Kabel aufgeladen werden. Das Gerät ist wasserdicht,
          versteckt und im Lenker fest verriegelt. Die Ortung erfolgt per App, dort wird auch der Akkustand angezeigt
          und bei Unterschreiten von 40% und 20% eine Auflade-Information auf dem Handy angezeigt.
          <Text fw='bold'>
            Der Hersteller bietet außerdem den Service der Rückholung im Diebstahlfall an und übernimmt in
            Zusammenarbeit mit der Polizei die Verfolgung.
          </Text>
        </Text>
      </Modal>
      <Card
        className={classes.container}
        h={'100%'}
        mb='md'>
        <Eyebrow text={'Beliebte Option'} />
        <Stack
          spacing={0}
          h={'100%'}>
          <Title
            order={3}
            my='md'>
            {GpsTrackerLabel}
          </Title>

          <ChecklistComponent
            {...headpointProps}
            preview={10000}
          />
          <Box style={{ flexGrow: 1 }} />

          {gpsProduct && (
            <Text>
              Ich möchte&nbsp;&nbsp;
              <Select
                maw={40}
                style={{ display: 'inline-block' }}
                size='sm'
                variant='unstyled'
                data={['1', '2', '3', '4', '5']}
                value={gpsProduct?.amount?.toString()}
                onChange={setGpsProducsAmount}
              />{' '}
              Tracker bestellen
            </Text>
          )}

          <Button
            compact
            fullWidth
            variant={value !== 'unknown' ? 'filled' : 'outline'}
            color={value !== 'unknown' ? 'primary' : 'primary'}
            leftIcon={value !== 'unknown' ? <FiCheck color='white' /> : null}
            onClick={useCallback(() => {
              onChange(value === 'unknown' ? 'rented' : 'none');
            }, [value, onChange])}>
            {value !== 'unknown' ? `Hinzugefügt für ${priceText}` : `Hinzufügen für ${priceText}`}
          </Button>
        </Stack>
      </Card>
    </>
  );
};
