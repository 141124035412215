import React, { ComponentType, useCallback, useMemo } from 'react';

import Progressbar from '@unserkunde/enscompare-components/src/components/feedback/Progressbar';

import { Anchor, Box, Container, Group } from '@mantine/core';
import { useIsPremiumserviceProcess } from './premiumserviceProcess/PremiumserviceProcessInfo';
import Success from 'forms/success/Success';
import EnsuranceList from 'forms/ensuranceList/EnsuranceList';
import AngebotFertig from 'forms/angebot/AngebotCompleted';
import VoucherSelectionPage from 'forms/InspectionVoucher/VoucherSelectionPage';
import { EnsPage } from '@/actions/progress';
import { PriceRequestSubmit } from '@/forms/PriceRequestSubmit';
import { AutoloadStandalone } from '@/features/AutoloadEns';
import { useAppSelector } from '@/hooks';
import { FrontendRedirectEntry } from '@/reducer/ensfields';
import { getQueryStrings } from '@/actions/shared/QueryParams';
import { OverlayDisplay } from './overlay/OverlayDisplay';
import { DefaultEnsProcessRenderer } from './processDefinition/DefaultEnsProcessRenderer';

import { FluentProgress } from './defaultFluentProcess/FluentProgress';
import { ContactBoxSmall } from '@/forms/ensuranceList/ContactBox';
import { useGlobalBackHandler } from './shared/GlobalBackContext';
import { ButtonLink } from '@unserkunde/enscompare-components';
import { EnsIcon } from '@/forms/shared/AvailalbeEnsIcons';
import { useGlobalProgress } from '@/processes/shared/GlobalProgressContext';

const FluentRenderer = () => {
  const target = useAppSelector((state) => state.router);

  return (
    <DefaultEnsProcessRenderer
      target={target as keyof typeof FluentProgress.processSteps}
      processDefinition={FluentProgress}
    />
  );
};

const useControl = (target: EnsPage) => {
  const isPremiumserviceProcess = useIsPremiumserviceProcess();

  const overlayName = useAppSelector((state) => state.overlay.overlayName);

  const globalProgressData = useGlobalProgress();

  return useMemo(() => {
    let CurrentControl: ComponentType = FluentRenderer;
    let stepperProgress: number | false = false;
    let stepperTitle: string | false = false;
    let showRedirect = false;

    stepperProgress = 5;
    stepperTitle = 'Berechnung';

    if (target === '') {
      if (globalProgressData?.prozeduralEntryQuestionsRoot) {
        stepperProgress = (globalProgressData.prozeduralEntryQuestionsRoot.percentage / 100) * 35 + 5;
      }
    } else if (target === 'secondBike') {
      CurrentControl = FluentRenderer;
      stepperProgress = 25;
    } else if (target === 'ensuranceList') {
      CurrentControl = EnsuranceList;
      stepperTitle = 'Angebote';
      stepperProgress = 40;
    } else if (target === 'personalInfo' || target === 'personalContactInfo') {
      CurrentControl = FluentRenderer;
      stepperTitle = 'Antrag';
      stepperProgress = 60;
    } else if (target === 'paymentInfo') {
      CurrentControl = FluentRenderer;
      stepperTitle = 'Antrag';
      stepperProgress = 70;
    } else if (target === 'bikeinfo') {
      CurrentControl = FluentRenderer;
      stepperProgress = 80;
      stepperTitle = 'Antrag';
    } else if (target === 'zusammenfassung') {
      CurrentControl = FluentRenderer;
      stepperProgress = 90;
      stepperTitle = 'Angaben überprüfen';
    } else if (target === 'requestPriceSubmit') {
      CurrentControl = PriceRequestSubmit;
      stepperProgress = 90;
      stepperTitle = 'Angebotsanfrage';
    } else if (target === 'angebotfertig') {
      CurrentControl = AngebotFertig;
      stepperProgress = 100;
      stepperTitle = 'Fertig - So geht es weiter';
    } else if (target === 'inpsectionVoucher') {
      CurrentControl = VoucherSelectionPage;
      stepperProgress = 10;
    } else if (target === 'order_success') {
      CurrentControl = Success;
      stepperProgress = 100;
      stepperTitle = 'Fertig - So geht es weiter';
    } else {
      //Ensurance Overview
      showRedirect = true;
    }

    if (isPremiumserviceProcess) {
      stepperProgress = false;
      stepperTitle = false;
    }

    if (overlayName) {
      CurrentControl = OverlayDisplay;
    }

    return [CurrentControl, stepperProgress, stepperTitle, showRedirect] as const;
  }, [target, isPremiumserviceProcess, overlayName, globalProgressData.prozeduralEntryQuestionsRoot]);
};

const InstanceRedirectItem = (props: { item: FrontendRedirectEntry }) => {
  const handler = useCallback(() => {
    window.ensRerenderWithApiSource(props.item.instanceRootUrl);
  }, [props.item]);

  return <Anchor onClick={handler}>{props.item.displayName}</Anchor>;
};

const InstanceRedirectControl = () => {
  const redirects = useAppSelector((state) => state.ensfields.frontendInstanceRedirect);

  if (!redirects || redirects.length === 0) return null;

  if (!getQueryStrings()?.showInstanceRedirect) return null;

  return (
    <Group position='right'>
      {redirects.map((redirect, index) => (
        <InstanceRedirectItem
          item={redirect}
          key={index}
        />
      ))}
    </Group>
  );
};

const DefaultProgress = (props) => {
  const { target } = props;

  const [CurrentControl, stepperProgress, stepperTitle, showRedirect] = useControl(target);

  const progressProps = useMemo(
    () => ({
      progress: { value: stepperProgress },
      title: stepperTitle,
      //showRedirect,
    }),
    [stepperProgress, stepperTitle]
  );

  const globalBackHandler = useGlobalBackHandler();
  const backControl = !globalBackHandler ? (
    <Box style={{ flexGrow: 1 }} />
  ) : (
    <Box style={{ flexGrow: 1 }}>
      <ButtonLink
        leftIcon={
          <EnsIcon
            icon='FaArrowLeft'
            useAppColor={true}
          />
        }
        onClick={globalBackHandler}>
        Zurück
      </ButtonLink>
    </Box>
  );

  return (
    <>
      {stepperProgress === false ? null : (
        <AutoloadStandalone hide>
          <Container
            px='0'
            fluid>
            {showRedirect && <InstanceRedirectControl />}
            <Progressbar
              preChildren={backControl}
              align='center'
              {...progressProps}>
              <ContactBoxSmall />
            </Progressbar>
          </Container>
        </AutoloadStandalone>
      )}
      <CurrentControl />
    </>
  );
};

export default DefaultProgress;
