import { getQueryStrings } from '@/actions/shared/QueryParams';
import { useAppDispatch, useAppSelector, useDynamicFields } from '@/hooks';
import React, { useCallback, useEffect } from 'react';
import { ProceduralCheckoutInputs } from '../../shared/proceduralCheckout/ProceduralCheckoutInputs';
import { confirmEnsuranceOverview } from '@/actions/progress';
import { GetIsDevMode } from '../../shared/IsDevMode';
import { Box } from '@mantine/core';
import { ProceduralSecondBikeSelection } from './ProceduralSecondBikeSelection';
import { useProgressReporting } from '@/processes/shared/GlobalProgressContext';
import { useEnsOptions } from '@/reducer/ensOptions';

export const useIsProcedural = () => {
  const ensOptions = useEnsOptions();
  return ensOptions.useProzeduralCheckout || !!getQueryStrings().ensProzeduralCheckout;
};

export type ProceduralPage = (
  | { questionsGetter: Parameters<typeof useDynamicFields>; bikeIndex?: number }
  | {
      type: 'special';
      page: 'secondBikeQuestion';
    }
  | {
      type: 'special';
      component: React.FunctionComponent<{ onContinue: () => void; onBack: () => void }>;
    }
) & { nextButtonLabel?: string };

const DefaultProcessViews: ProceduralPage[] = [
  {
    bikeIndex: 0,
    questionsGetter: ['objectEntryQuestionsTop'],
  },

  {
    questionsGetter: ['entryQuestionsTop'],
  },
  {
    questionsGetter: ['entryQuestionsMore'],
  },
  {
    bikeIndex: 0,
    questionsGetter: ['objectEntryQuestions'],
  },
  {
    type: 'special',
    page: 'secondBikeQuestion',
  },
  {
    bikeIndex: 1,
    questionsGetter: ['objectEntryQuestionsTop'],
  },
  {
    bikeIndex: 1,
    questionsGetter: ['objectEntryQuestions'],
  },
  {
    bikeIndex: 1,
    questionsGetter: ['entryQuestionsMore'],
  },
  {
    questionsGetter: ['entryQuestions'],
  },
  {
    questionsGetter: ['entryQuestionsMore'],
  },
];

export const ProceduralOverviewInput = (props: {
  onBack?: () => void;
  view?: ProceduralPage[];
  onCompleted?: () => void;
}) => {
  const view = props.view ?? DefaultProcessViews;

  const [viewsIndex, setViewsIndex] = React.useState(0);

  const dispatch = useAppDispatch();
  const onReloadEnsuranceList = useCallback(() => dispatch(confirmEnsuranceOverview()), [dispatch]);

  const currentView = view[viewsIndex];
  const isSpectialPage = 'type' in currentView;

  const questions = !isSpectialPage
    ? useDynamicFields(...currentView.questionsGetter)
    : useDynamicFields(null, () => false);

  const currentBikeIndex = !isSpectialPage ? currentView.bikeIndex : undefined;
  const currentBikeId = useAppSelector((state) =>
    currentBikeIndex !== undefined ? Object.keys(state.userData.bikes)[currentBikeIndex] : undefined
  );

  useProgressReporting('prozeduralEntryQuestionsRoot', viewsIndex, view.length);

  const onViewCompleted = useCallback(() => {
    if (viewsIndex + 1 === view.length) {
      if (!props.onCompleted) onReloadEnsuranceList();
      else props.onCompleted();
    } else {
      setViewsIndex((i) => i + 1);
    }
  }, [viewsIndex, props.onCompleted, questions, currentView, view, onReloadEnsuranceList]);

  const onViewBack = useCallback(() => {
    if (viewsIndex === 0) {
      props.onBack?.();
    } else {
      setViewsIndex((i) => i - 1);
    }
  }, [viewsIndex]);

  useEffect(() => {
    if (currentBikeIndex !== undefined && !currentBikeId) {
      onViewCompleted();
    }
  }, [currentBikeIndex, currentBikeId, onViewCompleted, currentView]);

  return (
    <Box
      mih='300px'
      style={{ display: 'flex', flexDirection: 'column' }}
      pt={'120px'}>
      {GetIsDevMode() && (
        <pre>
          {JSON.stringify(
            {
              currentView: currentView || '-keins-',
              isSpectialPage: isSpectialPage || false,
              currentBikeIndex: JSON.stringify(currentBikeIndex),
              currentBikeId: currentBikeId,
              viewsTotal: view.length,
              viewsIndex: viewsIndex,
              questions,
            },
            null,
            2
          )}
        </pre>
      )}

      {!isSpectialPage && (
        <ProceduralCheckoutInputs
          fields={questions}
          bikeId={currentBikeId}
          onCompleted={onViewCompleted}
          onPrevious={onViewBack}
          key={viewsIndex}
          pageId={viewsIndex}
          nextButtonLabel={currentView.nextButtonLabel}
        />
      )}
      {isSpectialPage && 'page' in currentView && currentView.page === 'secondBikeQuestion' && (
        <ProceduralSecondBikeSelection
          onContinue={onViewCompleted}
          onBack={onViewBack}
        />
      )}
      {isSpectialPage &&
        'component' in currentView &&
        (() => {
          const Component = currentView.component;
          return (
            <Component
              onContinue={onViewCompleted}
              onBack={onViewBack}
            />
          );
        })()}
    </Box>
  );
};
