import React, { Suspense } from 'react';

import DefaultProgress from './processes/DefaultProgress';
import DevicemodeSelect from './forms/DevicemodeSelect';
import { selectorIsInitialLoading, useAppSelector } from './hooks';
import EnsLoadingIndicator from 'forms/shared/loading/EnsLoadingIndicator';
import { useIsPremiumserviceProcess } from 'processes/premiumserviceProcess/PremiumserviceProcessInfo';
import { useAutoloadEnsurances } from './features/AutoloadEns';
import { useIsOfferRequestProcess } from './processes/offerRequestProcess/IsOfferRequestProcess';

const GewerblicherProgress = React.lazy(() => import('@/processes/gewerblicherProgress/GewerblicherProgress'));
const PremiumserviceProcess = React.lazy(() => import('@/processes/premiumserviceProcess/PremiumserviceProcess'));
const OfferRequestProcess = React.lazy(() => import('@/processes/offerRequestProcess/OfferRequestProcess'));

function Router() {
  const target = useAppSelector((state) => state.router);
  const devkey = useAppSelector((state) => state.devicemode.devkey);
  const isInitialLoading = useAppSelector(selectorIsInitialLoading);

  const autoloadEnsurances = useAutoloadEnsurances();

  const isOfferRequestProcess = useIsOfferRequestProcess();
  const isPremiumserviceProcess = useIsPremiumserviceProcess();

  if (isInitialLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <EnsLoadingIndicator relative />
      </div>
    );
  }

  if (devkey === 'gewerblich') {
    return <GewerblicherProgress target={target} />;
  }

  if (isPremiumserviceProcess) {
    return <PremiumserviceProcess />;
  }

  if (isOfferRequestProcess) {
    return <OfferRequestProcess />;
  }

  if (!devkey && autoloadEnsurances !== 'compare_standalone' && !isOfferRequestProcess) {
    return <DevicemodeSelect />;
  }

  return <DefaultProgress target={target} />;
}

function SuspenseLoadingIndicator() {
  return (
    <div style={{ width: '100%' }}>
      <EnsLoadingIndicator />
    </div>
  );
}

export default function RouterWithSuspense() {
  return (
    <Suspense fallback={<SuspenseLoadingIndicator />}>
      <Router />
    </Suspense>
  );
}
