import React, { useCallback, useState } from 'react';
import { Container, Divider, Flex, TextInput, Title } from '@mantine/core';

import { ButtonLink } from '@unserkunde/enscompare-components/src/components/components';
import { FaAngleLeft } from 'react-icons/fa';
import { useAppDispatch } from '../../hooks';
import { navigateTo } from '../../actions/progress';
import { OfferRequestProcessFilterSettings } from './OfferRequestProcessFilterSettings';
import { OfferRequestProcessSettingNames } from './shared/OfferRequestProcessSettingNames';
import { GetOfferRequestProcessSetting, SetOfferRequestProcessSetting } from './data/OfferRequestProcessStore';
import { FilterProposalToggleButton } from 'forms/ensuranceList/CustomFilterProposal';
import { useEnsOverviewStyles } from '@/forms/OverviewControls/EnsuranceOverview';

export { OfferRequestProcessSettingNames } from './shared/OfferRequestProcessSettingNames';
export { GetOfferRequestProcessSetting, SetOfferRequestProcessSetting } from './data/OfferRequestProcessStore';

export const useStatefulLocalStorageSetting = (propName, eventValueSelector = (e) => e) => {
  const [value, setValue] = useState(GetOfferRequestProcessSetting(propName, ''));
  const onSave = useCallback(() => SetOfferRequestProcessSetting(propName, value), [propName, value]);

  const valueSetEvent = useCallback((e) => setValue(eventValueSelector(e)), [setValue, eventValueSelector]);

  return [value, valueSetEvent, onSave] as const;
};

export const useLocalStorageSetting = (propName, selector = (e) => e): [string, (...args: any[]) => void] => {
  const [, updateState] = useState({});
  const value = GetOfferRequestProcessSetting(propName, '');
  const setter = useCallback(
    (value) => SetOfferRequestProcessSetting(propName, selector(value)) && updateState({}),
    [propName]
  );

  return [value, setter];
};

const OfferSettingsControl = () => {
  const [refererId = '', setRefererId, onSaveRefererId] = useStatefulLocalStorageSetting(
    OfferRequestProcessSettingNames.refererId,
    (e) => e.currentTarget.value
  );
  const [presetId = '', setPresetId, onSavePresetId] = useStatefulLocalStorageSetting(
    OfferRequestProcessSettingNames.presetId,
    (e) => e.currentTarget.value
  );
  const [voucherCode = '', setVoucherCode, onSaveVoucherCode] = useStatefulLocalStorageSetting(
    OfferRequestProcessSettingNames.voucherCode,
    (e) => e.currentTarget.value
  );

  const [useFilterProposal, setUseFilterProposal] = useLocalStorageSetting(
    OfferRequestProcessSettingNames.useRecommendedFilter
  );

  return (
    <>
      <Container>
        <Flex
          gap='sm'
          justify='flex-start'
          align='stretch'
          direction='column'
          pb={'sm'}>
          <Title order={2}>Einstellungen</Title>
          <TextInput
            label='Benutzername'
            value={refererId}
            onChange={setRefererId}
            onBlur={onSaveRefererId}
          />
          <TextInput
            label='Preset ID'
            value={presetId}
            onChange={setPresetId}
            onBlur={onSavePresetId}
          />
          <TextInput
            label='Gutscheincode'
            value={voucherCode}
            onChange={setVoucherCode}
            onBlur={onSaveVoucherCode}
          />

          <Divider />

          <Title order={4}>Möchtest du unsere Filterempfehlungen benutzen?</Title>
          <FilterProposalToggleButton
            checked={!!useFilterProposal}
            onClick={() => setUseFilterProposal(!useFilterProposal ? '1' : '')}
          />

          <Divider />

          <Title order={4}>Filtereinstellungen</Title>
          <OfferRequestProcessFilterSettings />
        </Flex>
      </Container>
    </>
  );
};

export default OfferSettingsControl;
