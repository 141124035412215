import { CustomFrontendFilter } from '../actions/types/CustomFrontendFilter';
import FormularEvaluate, { GetEvaluationContext } from '@/lib/FormularEvaluate';

export const CustomFilterSetActiveFilters = (filters: CustomFrontendFilter[]) => {
  return { type: 'CUSTOM_FILTER_SET_ACTIVE', filters };
};

const startProposalData = {
  filterProposals: {},
  filterApplied: false,
  proposalText: '',
};

export type FilterReducesType = {
  filter: CustomFrontendFilter[];
  activeFilter: CustomFrontendFilter[];
  filterValues: any;
  filterValueDefaults: any;
  isChanged: boolean;
  [x: string]: any;
};

const customFilter = (
  state: FilterReducesType = {
    filter: [],
    activeFilter: [],
    filterValues: {},
    filterValueDefaults: {},
    isChanged: false,
    filterProposalData: { ...startProposalData },
  },
  action
): FilterReducesType => {
  if (action.type === 'ENS_FIELDS_LOADED' && action.mode === 'default') {
    return {
      ...state,
      filter: action.values.frontendFilter,
      filterValueDefaults: action.values.frontendFilter
        ?.filter((f) => f.startValue)
        .reduce((object, filter) => {
          return {
            ...object,
            [filter.internalName]: (() => {
              const context = GetEvaluationContext();
              const evalResult = FormularEvaluate(filter.startValue, context);
              return filter.type === 'boolean' ? !!evalResult : evalResult;
            })(),
          };
        }, {}),
    };
  } else if (action.type === 'APPLY_FILTER_PROPOSAL') {
    return {
      ...state,
      filterProposalData: { ...state.filterProposalData, filterApplied: true },
      filterValues: { ...state.filterValues, ...state.filterProposalData.filterProposals },
    };
  } else if (action.type === 'UNAPPLY_FILTER_PROPOSAL') {
    const reversedFilterValues = { ...state.filterProposalData.filterProposals };
    Object.keys(reversedFilterValues).forEach((key) => (reversedFilterValues[key] = !reversedFilterValues[key]));
    return {
      ...state,
      filterProposalData: { ...state.filterProposalData, filterApplied: false },
      filterValues: { ...state.filterValues, ...reversedFilterValues },
    };
  } else if (action.type === 'CUSTOM_FILTER_SET_ACTIVE') {
    return { ...state, activeFilter: action.filters };
  } else if (action.type === 'SET_FILTER_VALUE') {
    const changed = state.filterValues[action.name] !== action.value;
    return {
      ...state,
      filterValues: { ...state.filterValues, [action.name]: action.value },
      isChanged: state.isChanged || changed,
    };
  } else if (action.type === 'FILTER_PROPOSALS_LOADED') {
    return {
      ...state,
      filterProposalData: {
        filterApplied: false,
        proposalText: action.proposalText,
        filterProposals: action.filterProposals.reduce((p, n) => ({ ...p, ...n }), {}),
      },
    };
  } else if (action.type === 'FILTER_PROPOSALS_RESET') {
    return {
      ...state,
      filterValues: action.valuesOverride
        ? { ...action.valuesOverride }
        : {
            ...state.filterValueDefaults,
          },
      isChanged: false,
      filterProposalData: { ...startProposalData },
    };
  }

  return state;
};

export default customFilter;
